import copydeck from "./copydeck.csv";

export default {
  get(KEY, LANG) {
    var __FOUND = copydeck.find(function (cd, index) {
      if (cd.variable == KEY) {
        return true;
      }
    });

    if (__FOUND) {
      if (__FOUND.valeur_fr != null && LANG === "fr")
        return __FOUND.valeur_fr.trim();
      else if (__FOUND.valeur_nl != null && LANG === "nl")
        return __FOUND.valeur_nl.trim();
      else if (__FOUND.valeur_be != null && LANG === "be")
        return __FOUND.valeur_be.trim();
      else return KEY;
    } else {
      return KEY;
    }
  },
};
